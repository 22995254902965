// This file is implicitly imported before all stylesheets.
// This allows you to overwrite variables and mixins (and define new ones).
// They will be available in any scss file.

// IMPORTANT: This means you should not add any styling that may add rules to the eventual output.
//            In other words, this file should ONLY contain variables, mixins or placeholder selectors.
//            If you put any selectors here, they will be duplicated for every component. Nobody wants that.
//            You can also import any scss file you want, but the same idea applies to those.

$green-meemoo: #00C8AA;
$toolbar-meemoo: #1e2b38;

$base-color: $green-meemoo;
$action-color: $green-meemoo;

$toolbar-background-color: $toolbar-meemoo;
$toolbar-background-color-dark: black;
$toolbar-background-color-light: tint($toolbar-meemoo, 20%);
$secondary-menu-background: $toolbar-background-color-light;

$url-dropdown-hover: "~@skryv/bundle/theme/assets/images/dropdown-hover.png";
$url-dropdown-r-hover: "~@skryv/bundle/theme/assets/images/dropdown-r-hover.png";
$url-dropdown-l-hover: "~@skryv/bundle/theme/assets/images/dropdown-l-hover.png";
$url-comment-hover: "~@skryv/bundle/theme/assets/images/comment-hover.png";
