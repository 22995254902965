// This file is imported after all core/plugin stylesheets.
// This allows you to overwrite any styling you want.
// Keep in mind variables and mixins defined here will have no effect on earlier imported styling.
// If you want that, you should define them in includes.scss.
// Anything defined in the includes files (from core and theme) is available for use.

skr-app {
  skr-brand-logo {
    margin-right: 15px;
    .menu-icon img {
      height: 65px;
      padding: 6px 10px;
    }
  }
}
